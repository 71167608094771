import { ACCION_EJEC } from "@/utils/consts"

export default {
  async selectParteTrabajoMatSist (Vue, idparteTrabajoMatsist) {
    const tables = Vue.$offline.db.tables
    return (await Vue.$offline.db
      .select()
      .from(tables.parte_trabajo_matsist)
      .innerJoin(
        tables.orden_trabajo_matsist,
        tables.parte_trabajo_matsist.idorden_trabajo_matsist.eq(tables.orden_trabajo_matsist.idorden_trabajo_matsist)
      )
      .innerJoin(
        tables.material_sistema,
        tables.orden_trabajo_matsist.idmaterial_sistema.eq(tables.material_sistema.idmaterial_sistema)
      )
      .innerJoin(
        tables.subsis,
        tables.material_sistema.idsubsis.eq(tables.subsis.idsubsis)
      )
      .innerJoin(
        tables.tsubsis,
        tables.subsis.idtsubsis.eq(tables.tsubsis.idtsubsis)
      )
      .innerJoin(
        tables.articulo,
        tables.material_sistema.idarticulo.eq(tables.articulo.idarticulo)
      )
      .leftOuterJoin(
        tables.fabricante,
        tables.material_sistema.idfabricante.eq(tables.fabricante.idfabricante)
      )
      .leftOuterJoin(
        tables.agente_propelente,
        tables.material_sistema.idagente_propelente.eq(tables.agente_propelente.idagente_propelente)
      )
      .leftOuterJoin(
        tables.agente_extintor,
        tables.material_sistema.idagente_extintor.eq(tables.agente_extintor.idagente_extintor)
      )
      .leftOuterJoin(
        tables.timportancia_material,
        tables.material_sistema.idtimportancia_material.eq(tables.timportancia_material.idtimportancia_material)
      )
      .innerJoin(
        tables.parte_trabajo,
        tables.parte_trabajo_matsist.idparte_trabajo.eq(tables.parte_trabajo.idparte_trabajo)
      )
      .innerJoin(
        tables.orden_trabajo,
        tables.orden_trabajo_matsist.idorden_trabajo.eq(tables.orden_trabajo.idorden_trabajo)
      )
      .innerJoin(
        tables.sistema,
        tables.orden_trabajo.idsistema.eq(tables.sistema.idsistema)
      )
      .innerJoin(
        tables.cliente,
        tables.sistema.idcliente.eq(tables.cliente.idcliente)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.parte_trabajo_matsist.idparte_trabajo_matsist.eq(idparteTrabajoMatsist)
        )
      )
      .exec())[0]
  },
  async selectSiguienteAnteriorMaterial (Vue, idgrupoChecklistOt, idparteTrabajoMatsist, siguiente, norden) {
    const tables = Vue.$offline.db.tables
    let materialIrA = Vue.$offline.db
      .select()
      .from(tables.parte_trabajo_matsist)
      .innerJoin(
        tables.orden_trabajo_matsist,
        tables.parte_trabajo_matsist.idorden_trabajo_matsist.eq(tables.orden_trabajo_matsist.idorden_trabajo_matsist)
      )
      .innerJoin(
        tables.material_sistema,
        tables.orden_trabajo_matsist.idmaterial_sistema.eq(tables.material_sistema.idmaterial_sistema)
      )
      .innerJoin(
        tables.pregunta_checklist_ot,
        tables.orden_trabajo_matsist.idorden_trabajo_matsist.eq(tables.pregunta_checklist_ot.idorden_trabajo_matsist)
      )
      .innerJoin(
        tables.grupo_checklist_ot,
        tables.pregunta_checklist_ot.idgrupo_checklist_ot.eq(tables.grupo_checklist_ot.idgrupo_checklist_ot)
      )
      .innerJoin(
        tables.checklist_ot,
        tables.grupo_checklist_ot.idchecklist_ot.eq(tables.checklist_ot.idchecklist_ot)
      )
    if (siguiente) {
      materialIrA = await materialIrA
        .where(
          Vue.$offline.db.op.and(
            tables.pregunta_checklist_ot.idgrupo_checklist_ot.eq(idgrupoChecklistOt),
            tables.checklist_ot.estado.gt(0),
            tables.material_sistema.n_orden.gt(norden)
          )
        )
        .orderBy(tables.material_sistema.n_orden)
        .exec()
    } else {
      materialIrA = await materialIrA
        .where(
          Vue.$offline.db.op.and(
            tables.pregunta_checklist_ot.idgrupo_checklist_ot.eq(idgrupoChecklistOt),
            tables.checklist_ot.estado.gt(0),
            tables.material_sistema.n_orden.lt(norden)
          )
        )
        .orderBy(tables.material_sistema.n_orden, Vue.$offline.db.order.DESC)
        .exec()
    }
    if (materialIrA.length > 0) {
      return materialIrA[0]
    }
  },
  selectAccionEjecMaterial (Vue, idordenTrabajoMatsist) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.db
      .select()
      .from(tables.accion_ejec)
      .innerJoin(
        tables.maccion,
        tables.accion_ejec.idmaccion.eq(tables.maccion.idmaccion)
      )
      .innerJoin(
        tables.orden_trabajo_matsist,
        tables.accion_ejec.idorden_trabajo_matsist.eq(tables.orden_trabajo_matsist.idorden_trabajo_matsist)
      )
      .innerJoin(
        tables.material_sistema,
        tables.orden_trabajo_matsist.idmaterial_sistema.eq(tables.material_sistema.idmaterial_sistema)
      )
      .innerJoin(
        tables.articulo,
        tables.material_sistema.idarticulo.eq(tables.articulo.idarticulo)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.accion_ejec.idorden_trabajo_matsist.eq(idordenTrabajoMatsist),
          tables.accion_ejec.idest_accion_ejec.in([
            ACCION_EJEC.estados.pendiente, ACCION_EJEC.estados.realizada
          ]),
        )
      )
      .orderBy(tables.maccion.orden)
      .exec()
  },
}
