<template>
  <b10-base>
    <b10-toolbar
      :title="title"
      :options="toolbarOptions"
      @click-option="clickToolbarOption"
    >
      <volver-parte-checklist-menu-items
        slot="menuItemsLongPress"
        :idparte-trabajo="routeParams.idparte_trabajo"
        :idchecklist-ot="idsParaVolerAtras.idchecklist_ot"
        :idorden-trabajo-matsist="idsParaVolerAtras.idorden_trabajo_matsist"
        :idsubsis="idsParaVolerAtras.idsubsis"
      />
    </b10-toolbar>

    <b10-page-content>
      <b10-view-summary
        :item="formattedItem"
        :showing.sync="showingDialogs.moreInfo"
        :loading-data="loadingData"
        :list-item-lines="3"
        :show-alerts="!routeFromBrowserHistory"
      >
        <template
          slot="extraSubtitle"
        >
          <v-chip
            v-if="formattedItem.material_sistema && formattedItem.material_sistema.fdesinstalacion"
            label
            small
            color="warning"
          >
            Desinstalado
          </v-chip>
        </template>
        <template
          slot="cardActionsLeft"
        >
          <v-btn
            v-show="grupoChecklistOt.idgrupo_checklist_ot && prevEnabled"
            icon
            color="primary"
            @click="clickSiguienteAnteriorMaterial(false)"
          >
            <v-icon>{{ $vuetify.icons.values.prev }}</v-icon>
          </v-btn>
          <v-btn
            v-show="grupoChecklistOt.idgrupo_checklist_ot && nextEnabled"
            icon
            color="primary"
            @click="clickSiguienteAnteriorMaterial(true)"
          >
            <v-icon>{{ $vuetify.icons.values.next }}</v-icon>
          </v-btn>
        </template>
      </b10-view-summary>
      <lparte-trabajo-material-afectado-view-acciones
        :key="keyAcciones"
        :acciones="acciones"
        :estado-parte-pendiente="estadoPartePendiente"
        :total-preguntas="totalPreguntas"
        @delete="clickDeleteAccion"
        @add-accion="clickAddAccion"
        @add-anomalia="clickAddAnomalia"
        @click-accion="clickAccion"
      >
        <template
          #switchAccion="slotProps"
        >
          <v-switch
            v-model="slotProps.accion.accion_ejec.idest_accion_ejec"
            :false-value="ACCION_EJEC.estados.pendiente"
            :true-value="ACCION_EJEC.estados.realizada"
            color="green"
            :disabled="!estadoPartePendiente"
            @change="switchAccionChange(slotProps.accion, slotProps.index)"
          />
        </template>
      </lparte-trabajo-material-afectado-view-acciones>
      <b10-view-details
        :details="details"
        @click-detail="clickDetail"
      />
      <lparte-trabajo-material-afectado-view-legend
        :showing.sync="showingDialogs.legends"
      />
      <b10-fab-button
        :icon="$vuetify.icons.values.search"
        @click="clickBuscarMaterial"
      />
      <buscar-material-afectado
        v-if="formattedItem.material_sistema && formattedItem.material_sistema.idsubsis"
        :showing.sync="showingDialogs.buscarMaterialAfectado"
        :idparte-trabajo="routeParams.idparte_trabajo"
        :idsubsis="formattedItem.material_sistema.idsubsis"
        @click-buscar-avanzado="clickBuscarMaterialAvanzado"
      />
    </b10-page-content>
  </b10-base>
</template>

<script>
import { viewPageMixin } from '@/mixins/viewPageMixin'
import _ from '@/utils/lodash'
import { nonEmpty } from '@/utils/templates'
import { get } from 'vuex-pathify'
import Data from './LparteTrabajoMaterialAfectadoViewData'
import {
  PARAMETRO, COMPORTAMIENTO_ACCION_REVISION, TINCLUSION, ACCION_EJEC, MACCION, GRUPO_CHECKLIST, CHECKLIST, PARTE_TRABAJO, TDATO_ARTICULO, ItemNotFoundError
} from '@/utils/consts'
import LparteTrabajoMaterialAfectadoViewAcciones from './components/LparteTrabajoMaterialAfectadoViewAcciones'
import LparteTrabajoMaterialAfectadoViewLegend from './components/LparteTrabajoMaterialAfectadoViewLegend'
import BuscarMaterialAfectado from '../components/BuscarMaterialAfectado'
import VolverParteChecklistMenuItems from '../components/VolverParteChecklistMenuItems'
import { NextRoute } from '@/router'
import { toDate, isValidDate } from '@/utils/date'
import colors from 'vuetify/lib/util/colors'

export default {
  components: {
    LparteTrabajoMaterialAfectadoViewLegend,
    LparteTrabajoMaterialAfectadoViewAcciones,
    BuscarMaterialAfectado,
    VolverParteChecklistMenuItems,
  },
  mixins: [viewPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      toolbarOptions: {
        edit: {
          title: 'Modificar ficha técnica',
          visible: true,
          icon: 'edit'
        },
        delete: {
          title: 'Quitar del parte',
          visible: true,
          icon: 'delete'
        },
        marcarTodoCorrecto: {
          title: 'Marcar todo correcto',
          visible: true,
          icon: 'checkAll'
        },
        annadirAccion: {
          title: 'Añadir acción',
          visible: true,
          icon: 'add'
        },
        annadirAnomalia: {
          title: 'Añadir anomalia',
          visible: false,
          icon: 'add'
        },
        legend: {
          title: 'Colores y leyendas',
          visible: true,
          icon: 'info'
        },
      },
      moreInfoRows: [
        { name: 'material_sistema.codigo', label: 'Código' },
        { name: 'material_sistema.descripcion', label: 'Descripción' },
        { name: 'material_sistema.unidades', label: 'Unidades' },
        { name: 'material_sistema.fsistema', label: 'Fecha de instalación', filter: this.$options.filters.shortDate },
        { name: 'timportancia_material.descripcion', label: 'Importancia' },
        { name: 'material_sistema.nserie', label: 'Nº de serie' },
        { name: 'material_sistema.nelemento', label: 'Nº de elemento' },
        { name: 'material_sistema.ubicacion', label: 'Ubicación' },
        { name: 'material_sistema.netiqueta', label: 'Nº de etiqueta' },
        { name: 'material_sistema.num_zona', label: 'Nº de zona' },
        { name: 'material_sistema.evento', label: 'Evento' },
        { name: 'material_sistema.funcion', label: 'Función' },
        { name: 'material_sistema.particion', label: 'Partición' },
        { name: 'material_sistema.fultrevision', label: 'Última revisión', filter: this.$options.filters.shortDate },
        // { name: '', label: 'Próxima revisión', filter: this.$options.filters.shortDate },
        { name: 'material_sistema.fult_retimbrado', label: 'Último retimbrado', filter: this.$options.filters.shortDate },
        // { name: '', label: 'Próximo retimbrado', filter: this.$options.filters.shortDate },
        { name: 'material_sistema.fcaducidad', label: 'Fecha de caducidad', filter: this.$options.filters.shortDate },
        { name: 'material_sistema.ffin_garantia', label: 'Fin de garantía', filter: this.$options.filters.shortDate },
        { name: 'fabricante.descripcion', label: 'Fabricante' },
        { name: 'material_sistema.ffabricacion', label: 'Fecha de fabricación', filter: this.$options.filters.shortDate },
        { name: 'agente_extintor.descripcion', label: 'Agente extintor' },
        { name: 'agente_propelente.descripcion', label: 'Agente propelente' },
        { name: 'material_sistema.volumen', label: 'Volumen' },
        { name: 'material_sistema.peso_total', label: 'Peso total' },
        { name: 'material_sistema.peso_agente_extintor', label: 'Peso agente extintor' },
        { name: 'material_sistema.eficacia', label: 'Eficacia' },
        { name: 'material_sistema.presion', label: 'Presión' },
        { name: 'material_sistema.observaciones', label: 'Observaciones' },
        { name: 'material_sistema.observaciones_internas', label: 'Observaciones internas' },
      ],
      tsubsis: {},
      grupoChecklistOt: {},
      showingDialogs: {
        moreInfo: false,
        legends: false,
        buscarMaterialAfectado: false,
      },
      prevEnabled: true,
      nextEnabled: true,
      acciones: [],
      GRUPO_CHECKLIST,
      CHECKLIST,
      ACCION_EJEC,
      todoContestado: false,
      keyAcciones: 1,
      idsParaVolerAtras: {
        idorden_trabajo_matsist: null,
        idsubsis: null,
        idchecklist_ot: null
      },
      totalPreguntas: 0,
    }
  },
  computed: {
    usuarioIdtecnico: get('usuario/idtecnico'),
    formattedItem () {
      if (!_.isEmpty(this.item.dataset) && !_.isEmpty(this.tsubsis)) {
        const item = _.cloneDeep(this.item.dataset)
        item.id = item.parte_trabajo_matsist.idparte_trabajo_matsist
        item.title = this.$options.filters.linebreaksBr(nonEmpty`
          ${item.material_sistema.descripcion}`)
        item.subtitle = this.$offline.materialSistema.resumenFichaTecnica(item)
        if (this.tsubsis.tsubsis.ver_mat_nelemento) {
          item.badge = item.material_sistema.nelemento
        } else if (this.tsubsis.tsubsis.ver_mat_ubicacion) {
          item.badge = item.material_sistema.ubicacion
        } else {
          item.badge = ''
        }
        item.badgeColor = 'info'
        item.alerts = []
        if (item.material_sistema.fdesinstalacion) {
          item.alerts.push({
            value: `Material DENSINSTALADO el ${this.$options.filters.shortDate(item.material_sistema.fdesinstalacion)}`,
            type: 'warning'
          })
        }
        this.setMoreInfoRowValue('material_sistema.nserie', 'visible', this.tsubsis.tsubsis.ver_mat_nserie)
        this.setMoreInfoRowValue('material_sistema.nelemento', 'visible', this.tsubsis.tsubsis.ver_mat_nelemento)
        this.setMoreInfoRowValue('material_sistema.ubicacion', 'visible', this.tsubsis.tsubsis.ver_mat_ubicacion)
        this.setMoreInfoRowValue('material_sistema.netiqueta', 'visible', this.tsubsis.tsubsis.ver_mat_netiqueta)
        this.setMoreInfoRowValue('material_sistema.num_zona', 'visible', this.tsubsis.tsubsis.ver_mat_num_zona)
        this.setMoreInfoRowValue('material_sistema.evento', 'visible', this.tsubsis.tsubsis.ver_mat_evento)
        this.setMoreInfoRowValue('material_sistema.funcion', 'visible', this.tsubsis.tsubsis.ver_mat_funcion)
        this.setMoreInfoRowValue('material_sistema.particion', 'visible', this.tsubsis.tsubsis.ver_mat_particion)
        this.setMoreInfoRowValue('material_sistema.fultrevision', 'visible', this.tsubsis.tsubsis.ver_mat_fult_revision)
        // (no existe aún en offline, es un campo calculado en el servidor)
        // this.setMoreInfoRowValue('', 'visible', this.tsubsis.tsubsis.ver_mat_fprox_revision)
        this.setMoreInfoRowValue('material_sistema.fult_retimbrado', 'visible', this.tsubsis.tsubsis.ver_mat_fult_retimbrado)
        // (no existe aún en offline, es un campo calculado en el servidor)
        // this.setMoreInfoRowValue('', 'visible', this.tsubsis.tsubsis.ver_mat_fprox_retimbrado)
        this.setMoreInfoRowValue('material_sistema.fcaducidad', 'visible', this.tsubsis.tsubsis.ver_mat_fcaducidad)
        this.setMoreInfoRowValue('material_sistema.ffin_garantia', 'visible', this.tsubsis.tsubsis.ver_mat_ffin_garantia)
        this.setMoreInfoRowValue('fabricante.descripcion', 'visible', this.tsubsis.tsubsis.ver_mat_idfabricante)
        this.setMoreInfoRowValue('material_sistema.ffabricacion', 'visible', this.tsubsis.tsubsis.ver_mat_ffabricacion)
        this.setMoreInfoRowValue('agente_extintor.descripcion', 'visible', this.tsubsis.tsubsis.ver_mat_idagente_extintor)
        this.setMoreInfoRowValue('agente_propelente.descripcion', 'visible', this.tsubsis.tsubsis.ver_mat_idagente_propelente)
        this.setMoreInfoRowValue('material_sistema.volumen', 'visible', this.tsubsis.tsubsis.ver_mat_volumen)
        this.setMoreInfoRowValue('material_sistema.peso_total', 'visible', this.tsubsis.tsubsis.ver_mat_peso_total)
        this.setMoreInfoRowValue('material_sistema.peso_agente_extintor', 'visible', this.tsubsis.tsubsis.ver_mat_peso_agente_extintor)
        this.setMoreInfoRowValue('material_sistema.eficacia', 'visible', this.tsubsis.tsubsis.ver_mat_eficacia)
        this.setMoreInfoRowValue('material_sistema.presion', 'visible', this.tsubsis.tsubsis.ver_mat_presion)
        item.moreInfo = this.buildMoreInfo()
        return item
      } else {
        return {}
      }
    },
    idchecklistOt () {
      if (this.grupoChecklistOt && this.grupoChecklistOt.idchecklist_ot) {
        return this.grupoChecklistOt.idchecklist_ot
      } else {
        return null
      }
    },
    estadoPartePendiente () {
      return (
        this.item &&
        this.item.dataset &&
        this.item.dataset.parte_trabajo &&
        this.item.dataset.parte_trabajo.estado === PARTE_TRABAJO.estados.pendiente
      )
    },
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      await this.loadItem()
      this.title = this.item.dataset.material_sistema.descripcion
      this.tsubsis = await this.$offline.tsubsis.row(this.item.dataset.subsis.idtsubsis)
      this.grupoChecklistOt = await this.$offline.parteTrabajoMatsist.selectGrupoChecklistOt(this.routeParams.idparte_trabajo_matsist)
      this.acciones = await Data.selectAccionEjecMaterial(this, this.item.dataset.orden_trabajo_matsist.idorden_trabajo_matsist)
      this.toolbarOptions.edit.visible = this.estadoPartePendiente && this.hasEditPerm(this.permissions.materialSistema.id)
      this.toolbarOptions.delete.visible = this.estadoPartePendiente
      this.toolbarOptions.marcarTodoCorrecto.visible = (
        this.estadoPartePendiente &&
        this.hasViewPerm(this.permissions.partes.marcarPreguntasTodoCorrecto)
      )
      this.toolbarOptions.annadirAccion.visible = this.estadoPartePendiente
      this.idsParaVolerAtras.idsubsis = this.item.dataset.subsis.idsubsis
      const checklistOt = await this.$offline.checklistOt.selectChecklistOtDeIdOrdenTrabajo(this.item.dataset.orden_trabajo.idorden_trabajo)
      this.idsParaVolerAtras.idchecklist_ot = checklistOt?.checklist_ot.idchecklist_ot

      await this.loadDetailTotals()
      // datos dinámicos de ficha técnica
      const tdatosArticulo = await this.$offline.articulo.selectTDatoArticulo(this.item.dataset.articulo.idficha_tecnica)
      for (let tdato of tdatosArticulo) {
        const datoMaterialSistema = await this.$offline.materialSistema.selectDatoMaterialSistemaValor(
          this.item.dataset.material_sistema.idmaterial_sistema, tdato.tdato_articulo.idtdato_articulo
        )
        let valor = ''
        // casos especiales por existir valores no estandarizados
        if (datoMaterialSistema.length > 0) {
          valor = datoMaterialSistema[0].dato_material_sistema.valor
          // fechas
          if (tdato.tdato_articulo.tipo_valor === TDATO_ARTICULO.tipos.fecha && valor) {
            const valorDate = toDate(valor)
            // si es un día válido
            if (isValidDate(valorDate)) {
              valor = this.$options.filters.shortDate(valorDate)
            }
          }
        }

        this.moreInfoRows.push({
          name: tdato.tdato_articulo.idtdato_articulo,
          label: tdato.tdato_articulo.descripcion,
          value: valor,
        })
      }
    },
    async loadItem () {
      this.loadingData = true
      try {
        const dataset = await Data.selectParteTrabajoMatSist(this, this.routeParams.idparte_trabajo_matsist)
        if (dataset) {
          this.$set(this.item, 'dataset', dataset)
        } else {
          const error = new ItemNotFoundError()
          error.extra = {
            title: 'Material no encontrado',
            message: `El material afectado que intentas visualizar no existe. Puede haber sido eliminado.`,
            redirectRoute: {
              name: 'offline__lparte-trabajo-material-afectado-subsis-list',
              params: {
                idparte_trabajo: this.routeParams.idparte_trabajo,
              }
            }
          }
          throw error
        }
      } finally {
        this.loadingData = false
      }
    },
    async loadDetailTotals () {
      this.initDetails()
      // checklist
      const datasetChecklist = await this.$offline.parteTrabajoMatsist.selectChecklistCount(
        this.routeParams.idparte_trabajo_matsist,
        this.idchecklistOt,
      )
      this.totalPreguntas = datasetChecklist.totalPreguntas
      if (datasetChecklist.totalPreguntas > 0) {
        this.idsParaVolerAtras.idorden_trabajo_matsist = this.item.dataset.orden_trabajo_matsist.idorden_trabajo_matsist
        const detailChecklist = this.addDetail(
          'checklist', 'Checklist', 'Preguntas del checklist', 'checklist'
        )
        this.todoContestado = datasetChecklist.totalPreguntasContestadas === datasetChecklist.totalPreguntas
        const progresoChecklist = Math.round(
          datasetChecklist.totalPreguntasContestadas * 100 / datasetChecklist.totalPreguntas
        )
        detailChecklist.badge = `${progresoChecklist}%`
        if (datasetChecklist.totalPreguntasConAnomalias > 0) {
          detailChecklist.badgeColor = colors.red.base
          detailChecklist.totals =
            `${datasetChecklist.totalPreguntasConAnomalias} ${this.$options.filters.pluralize('anomalía', datasetChecklist.totalPreguntasConAnomalias, 's')}`
        } else if (datasetChecklist.totalPreguntasContestadas === datasetChecklist.totalPreguntas) {
          detailChecklist.badgeColor = colors.green.base
        } else {
          detailChecklist.badgeColor = colors.grey.base
        }
        this.toolbarOptions.annadirAnomalia.visible = true
      }
      // anomalias
      const datasetAnomalias = await this.$offline.ordenTrabajoMatsist.selectAnomaliasResumen(
        this.item.dataset.orden_trabajo_matsist.idorden_trabajo_matsist
      )
      if (datasetAnomalias.length > 0) {
        const detailAnomalias = this.addDetail(
          'anomalias', 'Anomalías', 'Anomalías detectadas en el material', 'anomaliaChecklist'
        )
        let countAnomalias = 0
        let totalsAnomalias = []
        for (const rowAnomalias of datasetAnomalias) {
          totalsAnomalias.push(`${rowAnomalias.grado_anomalia.alias} (${rowAnomalias.count})`)
          countAnomalias += rowAnomalias.count
        }
        detailAnomalias.badge = countAnomalias || 0
        detailAnomalias.totals = totalsAnomalias.join(', ')
      }
      // acciones correctoras
      const datasetAccionesCorrectoras = await this.$offline.ordenTrabajoMatsist.selectAccionesCorrectorasResumen(
        this.item.dataset.orden_trabajo_matsist.idorden_trabajo_matsist
      )
      if (datasetAccionesCorrectoras.count > 0) {
        const detailAccionesCorrectoras = this.addDetail(
          'accionesCorrectoras', 'Acciones correctoras', 'Acciones correctoras realizadas o pendientes en el material', 'accionCorrectora'
        )
        detailAccionesCorrectoras.badge = datasetAccionesCorrectoras.count || 0
      }
      // ficha técnica
      this.addDetail('fichaTecnica', 'Ficha técnica', 'Datos del material afectado', 'fichaTecnica')
    },
    clickDetail (data) {
      if (data.detail.name === 'checklist') {
        this.$appRouter.push({
          name: 'offline__materiales-pregunta-list',
          params: {
            idparte_trabajo: this.routeParams.idparte_trabajo,
            idchecklist_ot: this.idchecklistOt,
            idorden_trabajo_matsist: this.item.dataset.orden_trabajo_matsist.idorden_trabajo_matsist,
          },
        })
      } else if (data.detail.name === 'fichaTecnica') {
        this.$appRouter.push({
          name: 'offline__material-sistema-view',
          params: {
            idsistema: this.item.dataset.sistema.idsistema,
            idmaterial_sistema: this.item.dataset.material_sistema.idmaterial_sistema,
          },
          query: {
            idparte_trabajo: this.routeParams.idparte_trabajo
          }
        })
      } else if (data.detail.name === 'anomalias') {
        this.$appRouter.push({
          name: 'offline__anomalia-list',
          params: {
            idparte_trabajo: this.routeParams.idparte_trabajo,
            idchecklist_ot: this.idchecklistOt,
          },
          query: {
            idorden_trabajo_matsist: this.item.dataset.orden_trabajo_matsist.idorden_trabajo_matsist,
          },
        })
      } else if (data.detail.name === 'accionesCorrectoras') {
        this.$appRouter.push({
          name: 'offline__accion-correctora-list',
          params: {
            idparte_trabajo: this.routeParams.idparte_trabajo,
            idchecklist_ot: this.idchecklistOt,
          },
          query: {
            idorden_trabajo_matsist: this.item.dataset.orden_trabajo_matsist.idorden_trabajo_matsist,
          },
        })
      }
    },
    addAccion () {
      this.$appRouter.push({
        name: 'offline__accion-ejec-add',
        params: {
          idparte_trabajo: this.routeParams.idparte_trabajo,
          idparte_trabajo_matsist: this.routeParams.idparte_trabajo_matsist,
        }
      })
    },
    async clickToolbarOption (option) {
      if (option === this.toolbarOptions.legend) {
        this.showingDialogs.legends = true
      } else if (option === this.toolbarOptions.edit) {
        this.$appRouter.push({
          name: 'offline__material-sistema-edit',
          params: {
            idmaterial_sistema: this.item.dataset.material_sistema.idmaterial_sistema,
          },
          query: {
            idparte_trabajo: this.routeParams.idparte_trabajo,
            idparte_trabajo_matsist: this.routeParams.idparte_trabajo_matsist,
          }
        })
      } else if (option === this.toolbarOptions.delete) {
        this.quitarMaterial()
      } else if (option === this.toolbarOptions.marcarTodoCorrecto) {
        await this.clickMarcarTodoCorrecto()
      } else if (option === this.toolbarOptions.annadirAccion) {
        this.addAccion()
      } else if (option === this.toolbarOptions.annadirAnomalia) {
        this.clickAddAnomalia()
      }
    },
    quitarMaterial () {
      const accionesRealizadas = _.filter(
        this.acciones, { accion_ejec: { idest_accion_ejec: ACCION_EJEC.estados.realizada } }
      )
      if (accionesRealizadas.length === 0) {
        this.$appRouter.push({
          name: 'offline__material-afectado-desinstalar',
          params: {
            idparte_trabajo: this.routeParams.idparte_trabajo,
            idparte_trabajo_matsist: this.routeParams.idparte_trabajo_matsist,
          },
        })
      } else {
        this.$alert.showSnackbarError('No puedes quitar un material con acciones realizadas')
      }
    },
    async clickMarcarTodoCorrecto () {
      const res = await this.$alert.showConfirm(
        `¿Deseas marcar todas las preguntas no contestadas de este material como correctas?`
      )
      if (res) {
        this.$loading.showManual('Marcando todo correcto')
        try {
          await this.$offline.ordenTrabajoMatsist.updateMaterialContestadoCorrecto(
            this.routeParams.idparte_trabajo,
            this.idchecklistOt,
            this.item.dataset.orden_trabajo_matsist.idorden_trabajo_matsist,
            this.usuarioIdtecnico
          )
          await this.$dirty.modified(this.$dirty.ENTITIES.local.parteTrabajoMatsist, this.routeParams.idparte_trabajo_matsist)
          await this.$dirty.modified(this.$dirty.ENTITIES.local.parteTrabajoMatsistSubsis, this.item.dataset.material_sistema.idsubsis)
          await this.loadPage()
        } finally {
          this.$loading.hide()
        }
      }
    },
    async clickSiguienteAnteriorMaterial (siguiente) {
      const materialIrA = await Data.selectSiguienteAnteriorMaterial(
        this,
        this.grupoChecklistOt.idgrupo_checklist_ot,
        this.routeParams.idparte_trabajo_matsist,
        siguiente,
        this.item.dataset.material_sistema.n_orden
      )
      if (materialIrA) {
        await this.$appRouter.replace({
          name: 'offline__material-afectado-view',
          params: {
            idparte_trabajo: materialIrA.parte_trabajo_matsist.idparte_trabajo,
            idparte_trabajo_matsist: materialIrA.parte_trabajo_matsist.idparte_trabajo_matsist
          },
        })
      } else {
        if (siguiente) {
          this.nextEnabled = false
          this.$alert.showSnackbarInfo(
            `${this.item.dataset.material_sistema.descripcion} es el último material del grupo`
          )
        } else {
          this.prevEnabled = false
          this.$alert.showSnackbarInfo(
            `${this.item.dataset.material_sistema.descripcion} es el primer material del grupo`
          )
        }
      }
    },
    async clickDeleteAccion (data) {
      const res = await this.$alert.showConfirm(
        `¿Deseas eliminar la acción "${data.accion.maccion.descripcion}"?`
      )
      if (res) {
        await this.$offline.accionEjec.deleteSync(
          data.accion.accion_ejec.idaccion_ejec,
          this.routeParams.idparte_trabajo
        )
        this.acciones.splice(data.index, 1)
        await this.$dirty.modified(this.$dirty.ENTITIES.local.parteTrabajoMatsist, this.routeParams.idparte_trabajo_matsist)
        await this.$dirty.modified(this.$dirty.ENTITIES.local.parteTrabajoMatsistSubsis, data.accion.material_sistema.idsubsis)
        // borrar retimbrado -> puede cambiar el facturable de la revisión
        await this.loadPage()
      }
    },
    clickAddAccion () {
      this.addAccion()
    },
    async switchAccionChange (accion, index) {
      const pasaARealizada = accion.accion_ejec.idest_accion_ejec === ACCION_EJEC.estados.realizada
      if (accion.maccion.clasificacion === MACCION.clasificacion.sustitucion && pasaARealizada)  {
        this.realizarSustitucion(accion, index)
      // La acción de recuperación es similar a la sustitución, pero lo hago aparte para que sea más comprensible
      } else if (accion.maccion.clasificacion === MACCION.clasificacion.recuperacion && pasaARealizada)  {
        this.realizarRecuperacion(accion, index)
      } else {
        let continuar = true
        // comprobar artículo de facturación
        if (pasaARealizada && accion.accion_ejec.facturable) {
          continuar = (await this.$offline.accionEjec.obtenerArticuloFacturacion(
            accion.accion_ejec.idaccion_ejec, accion.accion_ejec.idmaccion
          )).length > 0
        }
        if (continuar) {
          if (pasaARealizada) {
            // comprobar COMPORTAMIENTO_ACCION_REVISION
            const comportamientoRevision = parseInt(
              await this.$offline.parametro.valorParametro(PARAMETRO.parametros.COMPORTAMIENTO_ACCION_REVISION)
            )
            continuar = (
              (comportamientoRevision !== COMPORTAMIENTO_ACCION_REVISION.tipos.noPermitirRevisionSinContestarPreguntas) ||
              (this.todoContestado)
            )
            if (continuar) {
              await this.$offline.accionEjec.marcarAccionEjecRealizadaSync(
                accion.accion_ejec.idaccion_ejec,
                this.idchecklistOt,
                accion.accion_ejec.facturable,
                null,
                TINCLUSION.suministro,
                this.usuarioIdtecnico,
                this.routeParams.idparte_trabajo
              )
              if (accion.accion_ejec.facturable) {
                this.$alert.showSnackbarSuccess(`Acción "${accion.maccion.descripcion}" realizada`)
              } else {
                this.$alert.showSnackbarWarning(`Acción "${accion.maccion.descripcion}" realizada NO FACTURABLE`)
              }
            } else {
              this.$set(accion.accion_ejec, 'idest_accion_ejec', ACCION_EJEC.estados.pendiente)
              this.keyAcciones += 1
              this.$alert.showSnackbarError('Debes contestar primero todas las preguntas del checklist')
            }
          } else {
            // comparación por igualdad abstracta == porque idparte_trabajo puede ser string o number
            if (!accion.accion_ejec.idparte_trabajo || accion.accion_ejec.idparte_trabajo == this.routeParams.idparte_trabajo) {
              await this.$offline.accionEjec.marcarAccionEjecNoRealizadaSync(
                accion.accion_ejec.idaccion_ejec,
                this.idchecklistOt,
                this.routeParams.idparte_trabajo
              )
            } else {
              this.$set(accion.accion_ejec, 'idest_accion_ejec', ACCION_EJEC.estados.realizada)
              this.keyAcciones += 1
              this.$alert.showSnackbarError(
                `No se puede modificar la acción "${accion.maccion.descripcion_corta}" porque fue realizada en otro parte de trabajo`
              )
            }
          }
          // si es revision -> refrescar preguntas contestadas del checklist
          if (MACCION.esRevision(accion.maccion.clasificacion)) {
            await this.loadPage()
          }
        } else {
          this.$set(accion.accion_ejec, 'idest_accion_ejec', ACCION_EJEC.estados.pendiente)
          this.keyAcciones += 1
          this.$alert.showSnackbarError(
            `El artículo de facturación para el artículo "${accion.material_sistema.descripcion}"
            y la acción "${accion.maccion.descripcion}" no se encuentra definido`
          )
        }
        await this.$dirty.modified(this.$dirty.ENTITIES.local.parteTrabajoMatsist, this.routeParams.idparte_trabajo_matsist)
        await this.$dirty.modified(this.$dirty.ENTITIES.local.parteTrabajoMatsistSubsis, accion.material_sistema.idsubsis)
      }
    },
    async realizarRecuperacion (accion) {
      const selectArticuloFacturar = await this.$offline.accionEjec.obtenerArticuloFacturacion(
        accion.accion_ejec.idaccion_ejec, accion.accion_ejec.idmaccion
      )
      let articuloQueSeRecupera = null
      if (selectArticuloFacturar.length > 0) {
        articuloQueSeRecupera = selectArticuloFacturar[0].articulo
      } else {
        articuloQueSeRecupera = accion.articulo
      }
      await this.$offline.accionEjec.marcarAccionEjecRealizadaSync(
        accion.accion_ejec.idaccion_ejec,
        this.idchecklistOt,
        accion.accion_ejec.facturable,
        null,
        TINCLUSION.suministro,
        this.usuarioIdtecnico,
        this.routeParams.idparte_trabajo,
        articuloQueSeRecupera.idarticulo,
      )
    },
    async realizarSustitucion (accion) {
      // se recargará y el pendiente pasará a realizado
      // si cancela en cualquier momento ya queda en estado pendiente
      this.$set(accion.accion_ejec, 'idest_accion_ejec', ACCION_EJEC.estados.pendiente)
      const selectArticuloFacturar = await this.$offline.accionEjec.obtenerArticuloFacturacion(
        accion.accion_ejec.idaccion_ejec, accion.accion_ejec.idmaccion
      )
      let articuloQueSustituye = null
      if (selectArticuloFacturar.length > 0) {
        articuloQueSustituye = selectArticuloFacturar[0].articulo
      } else {
        articuloQueSustituye = accion.articulo
      }
      this.$appRouter.push({
        name: 'offline__articulo-list',
        params: {
          nextRoute: NextRoute(
            'offline__material-afectado-sustitucion',
            {
              idparte_trabajo: this.routeParams.idparte_trabajo,
              idparte_trabajo_matsist: this.routeParams.idparte_trabajo_matsist,
              idaccion_ejec: accion.accion_ejec.idaccion_ejec,
              idarticulo: null
            },
            {
              idchecklist_ot: this.idchecklistOt,
              idgrupo_checklist_ot: this.grupoChecklistOt.idgrupo_checklist_ot,
            },
            true,
          ),
          _filter: {
            codigo: {
              value: articuloQueSustituye.codigo,
            },
          },
        },
        query: {
          idttarifa: this.item.dataset.orden_trabajo.idttarifa,
          idbanco_precio: this.item.dataset.orden_trabajo.idbanco_precio,
        },
      })
    },
    clickBuscarMaterial () {
      this.showingDialogs.buscarMaterialAfectado = !this.showingDialogs.buscarMaterialAfectado
    },
    clickBuscarMaterialAvanzado () {
      this.showingDialogs.buscarMaterialAfectado = false
      this.$appRouter.push({
        name: 'offline__lparte-trabajo-material-afectado-list',
        params: {
          idparte_trabajo: this.routeParams.idparte_trabajo,
          _show_filter: true,
        },
        query: {
          idsubsis: this.item.dataset.material_sistema.idsubsis,
        },
      })
    },
    clickAccion (accion) {
      this.$appRouter.push({
        name: 'offline__accion-ejec-view',
        params: {
          idparte_trabajo: this.routeParams.idparte_trabajo,
          idparte_trabajo_matsist: this.routeParams.idparte_trabajo_matsist,
          idaccion_ejec: accion.accion_ejec.idaccion_ejec,
        },
      })
    },
    clickAddAnomalia () {
      this.$appRouter.push({
        name: 'offline__anomalia-material-add-list',
        params: {
          idparte_trabajo: this.routeParams.idparte_trabajo,
          idparte_trabajo_matsist: this.routeParams.idparte_trabajo_matsist,
          idchecklist_ot: this.idchecklistOt,
        },
      })
    }
  },
}
</script>
